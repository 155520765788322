import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  compareCoaAndNihProduct,
  compareCoaAndNihProductList,
} from "../redux/actions";

const UploadFileForLabel = ({ show, onClose = () => {}, updateList }) => {
  const modalStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyles = {
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    maxWidth: "540px",
    borderRadius: "5px",
    position: "relative",
    boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
  };

  const closeStyles = {
    color: "#aaa",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const footerStyles = {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  };

  const fileRowStyles = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  };

  const fileNameStyles = {
    marginLeft: "10px",
  };

  const dispatch = useDispatch();

  // REF FOR INPUT AND MODAL
  const coaFileInput = useRef(null);
  const nihFileInput = useRef(null);

  // LOCAL STATE
  const [file, setFile] = useState({
    coaFile: null,
    nihFile: null,
  });
  const [loading, setLoading] = useState(false);

  // HANDLE FILE CHANGE FUNCTION
  const handleFileChange = (event) => {
    const { name } = event.target;
    setFile({
      ...file,
      [name]: event.target.files[0],
    });
    if (name === "coaFile") {
      coaFileInput.current.value = null;
    } else {
      nihFileInput.current.value = null;
    }
  };

  // FOR OPEN FILE TYPE
  const handleOpenFileType = (fileType) => {
    if (fileType === "coaFile") {
      coaFileInput.current.click();
    } else {
      nihFileInput.current.click();
    }
  };

  //   // CALL API FOR UPLOAD DOCUMENT IN API
  const handleUpload = async () => {
    const formdata = new FormData();
    formdata.append("coa_product", file.coaFile);
    formdata.append("nih_product", file.nihFile);
    setLoading(true);
    await dispatch(compareCoaAndNihProduct(formdata));
    const data = await dispatch(compareCoaAndNihProductList());
    updateList(data.list);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    setFile({
      coaFile: null,
      nihFile: null,
    });
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div style={modalStyles} onClick={onClose}>
      <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
        <span style={closeStyles} onClick={onClose}>
          &times;
        </span>
        <h3>Upload document for compare product</h3>
        <hr />
        <div className="my-4">
          <div style={fileRowStyles}>
            <input
              ref={coaFileInput}
              type="file"
              name="coaFile"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <button
              onClick={() => handleOpenFileType("coaFile")}
              className="btn btn-primary"
            >
              Upload COA Document
            </button>
            {file.coaFile && (
              <span style={fileNameStyles}>{file.coaFile.name}</span>
            )}
          </div>
          <div style={fileRowStyles}>
            <input
              ref={nihFileInput}
              type="file"
              name="nihFile"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <button
              onClick={() => handleOpenFileType("nihFile")}
              className="btn btn-primary"
            >
              Upload Product label
            </button>
            {file.nihFile && (
              <span style={fileNameStyles}>{file.nihFile.name}</span>
            )}
          </div>
        </div>
        <hr />
        <div style={footerStyles}>
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
          <button
            type="button"
            onClick={() => handleUpload()}
            className="btn btn-success"
            disabled={loading || !file.coaFile || !file.nihFile}
          >
            {loading ? "Uploading..." : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadFileForLabel;
