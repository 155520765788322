export function formatNumber(number) {
  number = parseInt(number);
  let decPlaces = 0;
  if (number >= 1000 && number < 1000000) {
    decPlaces = 0;
  } else if (number >= 1000000 && number < 1000000000) {
    decPlaces = 1;
  } else if (number >= 1000000000 && number < 10000000000000) {
    decPlaces = 2;
  } else if (number >= 10000000000000) {
    decPlaces = 3;
  }
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i]?.toUpperCase();

      // We are done... stop
      break;
    }
  }

  return number;
}

export function filterLast24HourData(data) {
  // Function to check if a date is within the last 24 hours
  const isWithinLast24Hours = (dateString) => {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
    const date = new Date(dateString);
    return date > twentyFourHoursAgo;
  };

  // Filter the data array to get documents created within the last 24 hours
  const last24HourDocuments = data.filter((item) =>
    isWithinLast24Hours(item.created_on)
  );

  return last24HourDocuments.length;
}

export function filterLastWeekData(data) {
  const isWithinLastWeek = (dateString) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7); // Subtract 7 days
    const date = new Date(dateString);
    return date > sevenDaysAgo;
  };

  // Filter the data array to get documents created within the last 7 days
  const lastWeekDocuments = data.filter((item) =>
    isWithinLastWeek(item.created_on)
  );

  return lastWeekDocuments.length;
}
