import React, { Component } from "react";
import Header from "./Header";
import "../css/App.css";
import { isMobile } from "../redux/actions";
import { authContext } from "../adalConfig";

class CategoryAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      file: null,
      category_image: "",
      titleError: "",
      fileError: "",
      key: "",
    };
    this.onSave = this.onSave.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.category) {
      let category = this.props.location.state.category;
      this.setState({
        title: category.title,
        category_image: category.category_image,
        key: category.id,
      });
    }
  }

  onSave() {
    const { title, category_image, file, key } = this.state;

    let titleError = "",
      fileError = "";

    if (title.trim() === "") {
      titleError = "Please specify title.";
    }

    if (category_image.trim() === "" && file === null) {
      fileError = "Please select image.";
    }

    if (titleError !== "" || fileError !== "") {
      this.setState({
        titleError: titleError,
        fileError: fileError,
      });
      return;
    }

    const form = new FormData();
    form.append("title", title);
    form.append("fileName", file);
    form.append("category_image", category_image);
    form.append("created_by", authContext._user.profile.name);
    form.append("updated_by", authContext._user.profile.name);

    if (key !== "") {
      form.append("category_id", key);
    }

    this.props.saveCategory(form, key).then((id) => {
      if (id !== "") {
        alert("Category saved successfully");
        this.onCancel();
      } else {
        alert("Something went wrong. Please contact administrator");
      }
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0)
      this.setState({
        file: event.target.files[0],
        fileError: "",
        category_image: "",
      });
  }

  renderFooter() {
    return (
      <React.Fragment>
        {!isMobile() && (
          <div className="col-md-12">
            <button className="btn btn-default" onClick={() => this.onCancel()}>
              Cancel
            </button>
            <button
              className="btn btn-primary pull-right"
              onClick={() => this.onSave()}
              disabled={this.state.saving}
            >
              Save
            </button>
          </div>
        )}
        {isMobile() && (
          <div className="footer">
            <button
              className="btn btn-default btn-lg"
              onClick={() => this.onCancel()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary  btn-lg"
              onClick={() => this.onSave()}
              disabled={this.state.saving}
            >
              Save
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }

  onCancel() {
    this.setState({
      title: "",
      file: null,
      category_image: "",
      titleError: "",
      fileError: "",
      key: "",
    });
    this.props.history.push({ pathname: "/categorylist" });
  }

  render() {
    return (
      <div>
        <Header menuSelected="transactionadd" {...this.props} />
        <main className="container">
          {/* <br /> */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  {" "}
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-plus fa-stack-1x"></i>
                  </span>{" "}
                  {this.state.key === "" ? "Create " : "Edit "} Category
                </h3>
                <br />
                <div
                  className={
                    this.state.nameError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>Title</label>
                  <div className="input-group-btn">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.title}
                      onChange={(evt) =>
                        this.setState({
                          title: evt.target.value,
                          titleError: "",
                        })
                      }
                    />
                  </div>
                  {this.state.nameError !== "" && (
                    <span className="help-block">{this.state.titleError}</span>
                  )}
                </div>
                <div
                  className={
                    this.state.fileError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>Image </label>
                  {this.state.category_image !== "" && (
                    <img
                      src={this.state.category_image}
                      alt="Image"
                      className="form-control"
                    />
                  )}
                  <input
                    onChange={this.onFileChange}
                    type="file"
                    className="form-control"
                  />
                  {this.state.fileError !== "" && (
                    <span className="help-block">{this.state.fileError}</span>
                  )}
                </div>
                {isMobile() && (
                  <React.Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </React.Fragment>
                )}
                {this.renderFooter()}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CategoryAdd;
