// import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import { AuthenticationContext } from 'react-adal';
import myConfig from './config';


let redirectUri = (myConfig.REACT_APP_ADMIN_URL_UI && myConfig.REACT_APP_ADMIN_URL_UI !== '#{ADMIN_URL_UI}#') ? myConfig.REACT_APP_ADMIN_URL_UI : process.env.REACT_APP_ADMIN_URL_UI;

console.log("redirectUri", redirectUri)

export const adalConfig = {
  tenant: 'd918433f-9e63-4d9f-86eb-1f57d85a8d1f',
  clientId: 'd49597cf-06dd-4517-aa48-47f594354ff0',
  redirectUri: redirectUri, //process.env.REACT_APP_ADMIN_URL_UI, //'https://healthloqadminapp-dev.azurewebsites.net/',
  // endpoints: {
  //   api: 'd918433f-9e63-4d9f-86eb-1f57d85a8d1f',
  // },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);
console.log(authContext)

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

// export const adalApiFetch = (fetch, url, options) =>
//   adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

// export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
