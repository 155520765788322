// import * as constants from '../actionsType'

import myConfig from "../../config";

const axios = require("axios");
const config = {
  headers: { "content-type": "multipart/form-data" },
};

const apiURL =
  myConfig.REACT_APP_SERVICE_URL_API &&
  myConfig.REACT_APP_SERVICE_URL_API !== "#{SERVICE_URL_API}#"
    ? myConfig.REACT_APP_SERVICE_URL_API
    : process.env.REACT_APP_SERVICE_URL_API;

export const isMobile = () => {
  if (window.screen.width <= 480) {
    return true;
  } else {
    return false;
  }
};

export function saveOrganization(organization, key) {
  return (dispatch) => {
    if (key === "") {
      return axios
        .post(apiURL + "/organization/create", organization, config)
        .then((response) => {
          // console.log(response);
          if (response && response.status === 201) {
            return response.data.id;
          }
        })
        .catch((error) => {
          console.error(error);
          return "";
        });
    } else {
      return axios
        .post(apiURL + "/organization/edit", organization, config)
        .then((response) => {
          // console.log(response);
          if (response && response.status === 200) {
            return response;
          }
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
  };
}

export function verifyOrganization(organization) {
  return (dispatch) => {
    return axios
      .post(apiURL + "/organization/edit", organization)
      .then((response) => {
        if (response && response.status === 200) {
          return response;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };
}

export function getOrganizations(offset, limit, name) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/organization/search",
      data: {
        offset: offset,
        limit: limit,
        name: name,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return [];
      });
  };
}

export function getIndividuals(offset, limit, name, emailID, phoneNo) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/individual/search",
      data: {
        offset: offset,
        limit: limit,
        name: name,
        email_address: emailID,
        phone: phoneNo,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          // console.log("individuals", response.data)
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return [];
      });
  };
}

export function getSubscriptionCharges() {
  return (dispatch) => {
    return axios
      .post(apiURL + "/subscription/list")
      .then(function (response) {
        if (response && response.status === 200) {
          // console.log("individuals", response.data)
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return [];
      });
  };
}

export function saveIndividual(individual, key) {
  return (dispatch) => {
    if (key === "") {
      return axios({
        method: "post",
        url: apiURL + "/individual/create",
        data: {
          ...individual,
        },
      })
        .then(function (response) {
          // console.log(response);
          if (response && response.status === 201) {
            return response.data.id;
          }
        })
        .catch(function (error) {
          console.error(error);
          return "";
        });
    } else {
      return axios({
        method: "post",
        url: apiURL + "/individual/edit",
        data: {
          ...individual,
        },
      })
        .then(function (response) {
          // console.log(response);
          if (response && response.status === 200) {
            return response.data.id;
          }
        })
        .catch(function (error) {
          console.error(error);
          return "";
        });
    }
  };
}

export function saveSubscription(subscriptionData, key) {
  return (dispatch) => {
    if (key === "") {
      return axios({
        method: "post",
        url: apiURL + "/subscription/create",
        data: {
          ...subscriptionData,
        },
      })
        .then(function (response) {
          // console.log(response);
          if (response && response.status === 201) {
            return response.data.id;
          }
        })
        .catch(function (error) {
          console.error(error);
          return "";
        });
    } else {
      return axios({
        method: "post",
        url: apiURL + "/subscription/edit",
        data: {
          ...subscriptionData,
        },
      })
        .then(function (response) {
          // console.log(response);
          if (response && response.status === 200) {
            return response.data.id;
          }
        })
        .catch(function (error) {
          console.error(error);
          return "";
        });
    }
  };
}

export function getSubscriptionItem(subscriptionItemId) {
  return (dispatch) => {
    return axios({
      method: "get",
      url: apiURL + "/subscription/" + subscriptionItemId,
    })
      .then(function (response) {
        if (response && response.status === 200) {
          // console.log("individuals", response.data)
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return [];
      });
  };
}

export function getCategorys(offset, limit, name) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/category/search",
      data: {
        offset: offset,
        limit: limit,
        title: name,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return [];
      });
  };
}

export function saveCategory(category, key) {
  return (dispatch) => {
    if (key === "") {
      return axios
        .post(apiURL + "/category/create", category, config)
        .then((response) => {
          if (response && response.status === 201) {
            return response.data.id;
          }
        })
        .catch((error) => {
          console.error(error);
          return "";
        });
    } else {
      return axios
        .post(apiURL + "/category/edit", category, config)
        .then((response) => {
          if (response && response.status === 200) {
            return response;
          }
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
  };
}

export function updateCategoryStatus(data) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/category/update-status",
      data: {
        ...data,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return null;
      });
  };
}

export function getDocumentProtection(offset, limit, name) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/organization/get-document-protection-subscription",
      data: {
        offset: offset,
        limit: limit,
        name: name,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.error(error);
        return [];
      });
  };
}

export function uploadExeFile(file) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/exe-file/create",
      data: file,
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function getLatestExeFile() {
  return (dispatch) => {
    return axios({
      method: "get",
      url: apiURL + "/exe-file/get",
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function getForbiddenDocumentList(data) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/forbidden-document-list",
      data,
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

// GET ORGANIZATION LIST
export function getOrgoanizationList() {
  const data = {
    type: "authenticator",
  };
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/client-app/organization-list",
      data,
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

// VERIFY COA DOCUMENT
export function verifyCoaDocument(data) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-hash/verify-document",
      data,
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function getForbiddenDocumentListFromText(
  text,
  prompt,
  document_url,
  id
) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/forbidden-document-list-from-text",
      data: {
        text: text,
        prompt: prompt,
        document_url,
        id,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function getGenerateText(document_url, id) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/get-document-text",
      data: {
        document_url,
        id,
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        return null;
      });
  };
}

export function uploadDocument(file) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/dcoument-forbidden-substances",
      data: file,
    })
      .then(function (response) {
        if (response && response.status === 201) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function compareCoaAndNihProduct(file) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/comapare-coa-and-nih-label",
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function findingSubstance(file) {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/verify-document-with-ai",
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}

export function compareCoaAndNihProductList() {
  return (dispatch) => {
    return axios({
      method: "post",
      url: apiURL + "/document-ai/compare-coa-and-nih-label-list",
    })
      .then(function (response) {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log("error");
        return null;
      });
  };
}
