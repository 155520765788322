import React, { Component } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { isMobile } from "../redux/actions";
import Loader from "../components/Loader";
import { filterLast24HourData, filterLastWeekData } from "../utils/index";

class DocumentProtectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentProtectionList: [],
      offset: 0,
      limit: null,
      name: "",
      loading: true,
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
  }

  async componentDidMount() {
    let documentProtectionList = [];
    documentProtectionList = await this.props.getDocumentProtection(
      this.state.offset,
      this.state.limit,
      this.state.name
    );
    this.setState({
      documentProtectionList: documentProtectionList.data,
      loading: false,
    });
  }

  async onSearchClick() {
    this.setState({ loading: true });
    let documentProtectionList = [];
    documentProtectionList = await this.props.getDocumentProtection(
      this.state.offset,
      this.state.limit,
      this.state.name
    );
    this.setState({
      documentProtectionList: documentProtectionList.data,
      loading: false,
    });
  }

  async onClearSearch() {
    this.setState({ name: "", loading: true });
    let documentProtectionList = [];
    documentProtectionList = await this.props.getDocumentProtection(
      this.state.offset,
      this.state.limit,
      ""
    );
    this.setState({
      documentProtectionList: documentProtectionList.data,
      loading: false,
    });
  }

  render() {
    return (
      <div>
        <Header menuSelected="documentProtectionList" {...this.props} />
        <main className="container">
          {/* <br /> */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  {" "}
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-list fa-stack-1x"></i>
                  </span>{" "}
                  Document Protection List
                </h3>
                <br />
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        <b>Name</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ name: evt.target.value })
                        }
                        value={this.state.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-3" style={{ marginTop: "20px" }}>
                    <button
                      className="btn btn-default"
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={this.onSearchClick}
                    >
                      {" "}
                      Search
                    </button>
                    &nbsp;
                  </div>
                </div>
                <br />
                {this.state.loading && <Loader />}
                {!isMobile() && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* <th scope="col" style={{ verticalAlign: "top", width: "10%" }}></th> */}
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "top",
                            width: "10%",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Organization Name
                        </th>
                        {/* <th scope="col" style={{ verticalAlign: "top" , width:"20%"}}>Short Code</th> */}
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "top",
                            width: "10%",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Total Document
                        </th>
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "top",
                            width: "10%",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Documents uploaded in last week
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.documentProtectionList.map((t, index) => (
                        <tr key={t.id}>
                          <td
                            style={{
                              verticalAlign: "middle",
                              fontSize: "14px",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={t.org_thumb_url}
                                alt="Description of the org"
                                width="25"
                                height="25"
                                style={{ borderRadius: "50%" }}
                              ></img>
                              {t.name}{" "}
                              <a href={t.domain_name}>{t.domain_name}</a>
                            </span>
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              fontSize: "14px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              {t.organizationDocumentHashs?.length}
                              &nbsp;
                              {Number(
                                t.organizationDocumentHashs?.length &&
                                  (filterLast24HourData(
                                    t.organizationDocumentHashs
                                  ) *
                                    100) /
                                    t.organizationDocumentHashs?.length
                              ).toFixed(2) > 0 ? (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  (
                                  {Number(
                                    t.organizationDocumentHashs?.length &&
                                      (filterLast24HourData(
                                        t.organizationDocumentHashs
                                      ) *
                                        100) /
                                        t.organizationDocumentHashs?.length
                                  ).toFixed(2)}
                                  %
                                  <img
                                    src="up_arrow.png"
                                    alt="green_arrow"
                                    width="20px"
                                    height="15px"
                                  />
                                  in last 1 day)
                                </span>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            {t?.organizationDocumentHashs?.length
                              ? filterLastWeekData(
                                  t.organizationDocumentHashs
                                ) === 0
                                ? "-"
                                : filterLastWeekData(
                                    t.organizationDocumentHashs
                                  )
                              : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {isMobile() && (
                  <React.Fragment>
                    {this.state.documentProtectionList.map((t, index) => (
                      <div key={t.id} className="transaction">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <b>Organization Name: </b>
                          <img
                            src={t.org_thumb_url}
                            alt="Description of the org"
                            width="25"
                            height="25"
                            style={{ borderRadius: "50%" }}
                          ></img>
                          {t.name}
                          <a href={t.domain_name}>{t.domain_name}</a>
                        </div>
                        <div>
                          <b>Total Document: </b>
                          {t.organizationDocumentHashs?.length}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default DocumentProtectionList;
