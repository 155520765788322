import React, { Component } from 'react'
import Header from './Header'
import { isMobile } from '../redux/actions';
import Loader from '../components/Loader';
import { authContext } from '../adalConfig'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const activeOption = [
    {name:"Enable", option: true},
    {name:"Disable", option: false}
]
const subscriberhead = ["S. No.", "Subscription Duration", "Subscription Charges", "Currency"]
function isEmpty(value){
    return value === undefined || value === null || value === '';
}
const subscriptionChargeObj = [
    {
        subscription_duration: "Monthly",
        subscription_name: "Monthly",
        charges: null,
        currency: "USD",
        is_active: true
    },
    {
        subscription_duration: "Annually",
        subscription_name: "Annually",
        charges: null,
        currency: "USD",
        is_active: true
    }
]



class SubscriberAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            num_products_on_chain: -1,
            num_batches: -1,
            num_uploaded_documents: -1,
            num_seats: -1,
            subscription_page_layout: "",
            is_active: true,
            errornum_name: null,
            subscription_charge: [
                {
                    subscription_duration: "Monthly",
                    subscription_name: "Monthly",
                    charges: null,
                    currency: "USD",
                    is_active: true
                },
                {
                    subscription_duration: "Annually",
                    subscription_name: "Annually",
                    charges: null,
                    currency: "USD",
                    is_active: true
                }
            ],

            errornum_num_products_on_chain: null,
            errornum_num_batches: null,
            errornum_num_uploaded_documents: null,
            errornum_num_seats: null,
            errornum_subscription_page_layout: null,
            
            isFocus: false,
            loading: true,
            key: "",
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    async componentDidMount() {
        const { location : { state } } = this.props

        if(state && state.subscriptionID){
            const subscriptionItem = await this.props.getSubscriptionItem(state.subscriptionID)
            const { subscriptionCharge, id } = subscriptionItem
            if(subscriptionCharge && subscriptionCharge.length === 0){
                subscriptionItem.subscription_charge = subscriptionChargeObj
            }else{
                subscriptionItem.subscription_charge = subscriptionCharge
            }
            subscriptionItem.key = id
            subscriptionItem.loading=false
            this.setState(subscriptionItem)
        } else {
            this.setState({
                loading: false
            })
        }
       
    }

    changeHandler = (event) => {
        const { name, value } = event.target;
        let e = 'errornum_' + [name]
        this.setState({
            [name] : value,
            [e]: (value === '')
        })
    }

    changeListHandler = (event, index) => {
        const {name, value} = event.target
        const { subscription_charge } = this.state
        subscription_charge[index][name] = value
        this.setState(subscription_charge)
    }

    onCancel() {
        this.setState({
            name: '',
            num_products_on_chain: -1,
            num_batches: -1,
            num_uploaded_documents: -1,
            num_seats: -1,
            subscription_page_layout: "",
            is_active: true,
            errornum_name: null,
            subscription_charge: [
                {
                    subscription_duration: "Monthly",
                    subscription_name: "Monthly",
                    charges: null,
                    currency: "USD",
                    is_active: true
                },
                {
                    subscription_duration: "Annually",
                    subscription_name: "Annually",
                    charges: null,
                    currency: "USD",
                    is_active: true
                }
            ],

            errornum_num_products_on_chain: null,
            errornum_num_batches: null,
            errornum_num_uploaded_documents: null,
            errornum_num_seats: null,
            errornum_subscription_page_layout: null,
            
            isFocus: false,
            loading: false,
            key: "",
        });
        this.props.history.push({ pathname: '/subscriptionList' });
    }

    renderFooter() {
        return <React.Fragment>
            {!isMobile() && <div className="col-md-12">
                <button className="btn btn-default" onClick={() => this.onCancel()}>Cancel</button>
                <button className="btn btn-primary pull-right" onClick={() => this.onSave()} disabled={this.state.saving} >Save</button>
            </div>}
            {isMobile() && <div className="footer">
                <button className="btn btn-default btn-lg" onClick={() => this.onCancel()}>Cancel</button>
                <button className="btn btn-primary  btn-lg" onClick={() => this.onSave()} disabled={this.state.saving} >Save</button>
            </div>}
        </React.Fragment>
    }

    onSave = () => {
        const { name, num_products_on_chain, num_batches, num_uploaded_documents, num_seats, subscription_page_layout, subscription_charge, is_active, key} = this.state;

        if(
            isEmpty(name) ||
            isEmpty(num_products_on_chain) ||
            isEmpty(num_batches) ||
            isEmpty(num_uploaded_documents) ||
            isEmpty(num_seats) ||
            isEmpty(subscription_page_layout) ||
            isEmpty(subscription_charge[0].charges) ||
            isEmpty(subscription_charge[1].charges)
        ){
            alert("Please fill required fields!!!")
            window.scrollTo(0, 0);
            this.setState({
                errornum_name: isEmpty(name),
                errornum_num_products_on_chain: isEmpty(num_products_on_chain),
                errornum_num_batches: isEmpty(num_batches),
                errornum_num_uploaded_documents: isEmpty(num_uploaded_documents),
                errornum_num_seats: isEmpty(num_seats),
                errornum_subscription_page_layout: isEmpty(subscription_page_layout),
                isFocus: true
            })
            return false
        }else{
            const updateObj = {
                name,
                num_products_on_chain,
                num_batches,
                num_uploaded_documents,
                num_seats,
                subscription_page_layout,
                is_active,
                created_by: authContext._user.profile.name,
                updated_by: authContext._user.profile.name,
                subscription_charge
            }
            this.setState({
                loading: true
            })
            if(key !== ''){
                updateObj.id = key
            }
            this.props.saveSubscription(updateObj, key).then((id) => {
                if (id !== '') {
                    this.onCancel();
                    alert('Subscription saved successfully')
                }
                else {
                    alert('Something went wrong. Please contact administrator')
                }
            });
        }
    };

    // buttonHandler = (item, count, itemType) => {
    //     const { subscription_charge } = this.state
    //     if(itemType === 'edit'){
    //         subscription_charge[count].isEdit = true
    //         this.setState(subscription_charge)
    //     }
    //     else if(itemType === 'remove'){
    //         subscription_charge.splice(count, 1);
    //         console.log(subscription_charge, "subscription_charge")
    //         this.setState(subscription_charge)
    //     } 
    //     else{
    //         const { subscription_duration, subscription_duration, charges } = item
    //         if( subscription_duration !== '' && subscription_duration !== null && charges !== null){
    //             subscription_charge[count].isEdit = false
    //             this.setState(subscription_charge)
    //         } else {
    //             alert("Please specify Subcription Name, Subcription Duration(Days), and Subcription Charges")
    //         }
    //     }
    // }

    // buttonAddHandler = () => {
    //     const obj = {
    //         id:"",
    //         subscription_duration: "",
    //         subscription_duration: null,
    //         charges: null,
    //         currency: "USD",
    //         is_active: true,
    //         isEdit: true
    //     }
    //     const { subscription_charge } = this.state
    //     let isOld = false
    //     subscription_charge.forEach(item => {
    //         const { isEdit } = item
    //         if(isEdit){
    //             isOld = true
    //         }
    //     })
    //     if(!isOld){
    //         subscription_charge.push(obj)
    //         this.setState({
    //             subscription_charge
    //         })
    //     } else{
    //         alert("Please specify Subcription Name, Subcription Duration(Days), and Subcription Charges")
    //     }
    // }

    render() {
        const { name, num_products_on_chain, num_batches, num_uploaded_documents, num_seats, subscription_page_layout, isFocus, key, is_active, errornum_name, errornum_num_products_on_chain, errornum_num_seats, errornum_num_uploaded_documents, errornum_num_batches, errornum_subscription_page_layout, subscription_charge } = this.state
        const self = this;
        return <div className="page-add-subscription">
            <Header {...this.props} />
            <main className="container">
            {this.state.loading && <Loader />}
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0 title-page">
                                <i className="fa fa-plus-circle icon"></i>   
                                {key === '' ? 'Create ' : 'Edit '} Subscription
                            </h3>
                            <div className="form-subscriber">
                                <div className="row">
                                    <div className={errornum_name===true ? "col-12 col-md-6 has-error" : "col-12 col-md-6"}>
                                        <label className="form-subscriber-label">Name<span className="req">*</span></label>
                                        <input type="text" className="form-control"
                                            value={name}
                                            name="name"
                                            onChange={this.changeHandler} />
                                        {errornum_name===true && <div className="help-block">Please specify name.</div>}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-subscriber-label">Active<span className="req">*</span></label>
                                        <select className="form-control" value={is_active} onChange={this.changeHandler} name="is_active">
                                            {activeOption && activeOption.map((activeItem, index) => {
                                                    return <option key={index} value={activeItem.option}>{activeItem.name}</option>
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={errornum_num_products_on_chain===true ? "col-12 col-md-3 has-error" : "col-12 col-md-3"}>
                                        <label
                                        className="form-subscriber-label"
                                        >No. of Products<span className="req">*</span></label>
                                        <input type="text" className="form-control"
                                            value={num_products_on_chain}
                                            name="num_products_on_chain"
                                            onChange={this.changeHandler}/>
                                        {errornum_num_products_on_chain===true && <div className="help-block">Please specify No. of Products.</div>}
                                    </div>
                                    <div className={errornum_num_batches===true ? "col-12 col-md-3 has-error" : "col-12 col-md-3"}>
                                        <label
                                        className="form-subscriber-label"
                                        >No. of Batches<span className="req">*</span></label>
                                        <input type="text" className="form-control"
                                            value={num_batches}
                                            name="num_batches"
                                            onChange={this.changeHandler}
                                        />
                                        {errornum_num_batches===true && <div className="help-block">Please specify No. of Batches.</div>}
                                    </div>
                                    <div className={errornum_num_uploaded_documents===true ? "col-12 col-md-3 has-error" : "col-12 col-md-3"}>
                                        <label
                                        className="form-subscriber-label"
                                        >No. of Uploaded Documents<span className="req">*</span></label>
                                        <input type="text" className="form-control"
                                            value={num_uploaded_documents}
                                            name="num_uploaded_documents"
                                            onChange={this.changeHandler}
                                        />
                                        {errornum_num_uploaded_documents===true && <div className="help-block">Please specify No. of Uploaded Documents.</div>}
                                    </div>
                                    <div className={errornum_num_seats===true ? "col-12 col-md-3 has-error" : "col-12 col-md-3"}>
                                        <label
                                        className="form-subscriber-label"
                                        >No. of User Accounts<span className="req">*</span></label>
                                        <input type="text" className="form-control"
                                            value={num_seats}
                                            name="num_seats"
                                            onChange={this.changeHandler}
                                        />
                                        {errornum_num_seats===true && <div className="help-block">Please specify No. of User Accounts.</div>}
                                    </div>
                                </div>
                                <div className="row mb40">
                                    <div className={errornum_subscription_page_layout===true ? "col has-error" : "col"}>
                                        <label
                                        className="form-subscriber-label"
                                        >Subscription Page Layout<span className="req">*</span></label>
                                            <ReactQuill
                                                theme='snow'
                                                value={subscription_page_layout}
                                                onChange={spl => this.setState({subscription_page_layout: spl, errornum_subscription_page_layout: (spl === '<p><br></p>')})}
                                            />
                                        {errornum_subscription_page_layout===true && <div className="help-block">Please specify Subscription Page Layout.</div>}
                                        {/* <input type="text" className="form-control"
                                            value={this.state.firstName}
                                            onChange={(evt) => this.setState({ firstName: evt.target.value })} /> */}
                                    </div>
                                </div>
                                
                                <div className="border-bottom border-gray pb-2 mb-0 d-flex justify-content-between">
                                    <div>
                                        <h3 className="mb-0">Subscription Charges</h3>
                                    </div>
                                    {/* <div>
                                        <button className="btn btn-success mr10" onClick={() => this.buttonAddHandler()}>Add Plan</button>
                                    </div> */}
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                    <thead>
                                        <tr>
                                            {
                                                subscriberhead.map((item, index) => {
                                                    return (<th scope="col" key={index} style={{textAlign : ((subscriberhead.length - 1) === index) && 'right'}}>{item}</th>)
                                                })
                                            }
                                        </tr>
                                            {
                                                subscription_charge &&  subscription_charge.length ? subscription_charge.map((item, index) => {
                                                    return <SubscriptionCharge 
                                                    changeHandler={self.changeListHandler}
                                                    buttonHandler={self.buttonHandler}
                                                    isFocus={isFocus}
                                                    key={index}
                                                    item={item} index={index} />
                                                }) : <tr>
                                                    <td colSpan="6">
                                                    <div  className="txt-no-record">No Record Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </thead>
                                    </table>
                                </div>
                                
                            </div>
                            <div style={{marginTop: 30}}>
                                {this.renderFooter()}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}

const SubscriptionCharge = (props) => {
    const { item : {charges, currency, subscription_duration}, index, isFocus } = props
    //if(isEdit){
        return <tr key={index}>
        <td width="60" className="align-middle">{index + 1}</td>
        <td className="align-middle">
            {subscription_duration}
            {/* <input type="text" placeholder="Subscription Name" className="form-control" value={subscription_duration} name="subscription_duration" onChange={(event) => props.changeHandler(event, index)} /> */}
        </td>
        {/* <td className="align-middle">
            <input type="number" placeholder="Subcription Duration (Days)" className="form-control" value={subscription_duration} name="subscription_duration" onChange={(event) => props.changeHandler(event, index)} />
        </td> */}
        <td className={isFocus && (isEmpty(charges)) ? "align-middle has-error" : "align-middle"}>
            <input type="number" placeholder="Subcription Charges" className="form-control" value={charges} name="charges" onChange={(event) => props.changeHandler(event, index)} />
        </td>
        <td className="align-middle text-right" width="50">{currency}</td>
        {/* <td className="align-middle" align="right">
            <div className="d-flex justify-content-end">
                <button className="btn btn-success" onClick={() => props.buttonHandler(item, index, 'save')}>Save</button>
                <button className="btn btn-danger" onClick={() => props.buttonHandler(item, index, 'remove')}>Remove</button>
            </div>
        </td> */}
    </tr>
    //}
    // return <tr key={index}>
    //     <td width="60" className="align-middle">{index + 1}</td>
    //     <td className="align-middle">{subscription_duration}</td>
    //     <td className="align-middle">{subscription_duration}</td>
    //     <td className="align-middle">{charges}</td>
    //     <td className="align-middle">{currency}</td>
    //     <td className="align-middle" align="right">
    //         <div className="d-flex justify-content-end">
    //             <button className="btn btn-success mr10" onClick={() => props.buttonHandler(item, index, 'edit')}>Edit</button>
    //             <button className="btn btn-danger" onClick={() => props.buttonHandler(item, index, 'remove')}>Remove</button>
    //         </div>
    //     </td>
    // </tr>
}

export default SubscriberAdd