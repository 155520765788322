import React, { Component } from 'react'
import Header from './Header'
import { Link } from 'react-router-dom';
import { isMobile } from '../redux/actions';
import Loader from '../components/Loader';

class IndividualList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            individualList: [],
            offset: 0,
            limit: null,
            name: '',
            emailID: '',
            phoneNo: '',
            loading: true
        }
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
    }

    async componentDidMount() {
        let individualList = []
        individualList = await this.props.getIndividuals(this.state.offset, this.state.limit,
            this.state.name, this.state.emailID, this.state.phoneNo);
        this.setState({ individualList: individualList, loading: false })
    }

    async onSearchClick() {
        this.setState({ loading: true })
        let individualList = []
        individualList = await this.props.getIndividuals(this.state.offset, this.state.limit,
            this.state.name, this.state.emailID, this.state.phoneNo);
        this.setState({ individualList: individualList, loading: false })
    }

    async onClearSearch() {
        this.setState({name: '', emailID: '', phoneNo: '', loading: true});
        let individualList = []
        individualList = await this.props.getIndividuals(this.state.offset, this.state.limit,
            '', '', '');
        this.setState({ individualList: individualList, loading: false })
    }

    render() {
        return <div>
            <Header menuSelected="individualList" {...this.props} />
            <main className="container">
                {/* <br /> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0"> <span className="fa-stack fa-md">
                                <i className="fa fa-circle-thin fa-stack-2x"></i>
                                <i className="fa fa-list fa-stack-1x"></i>

                            </span> Individual List
                                {!isMobile() && <button className="btn btn-primary pull-right" onClick={() => this.props.history.push({ pathname: '/individual/create/', state: { plan: null } })}>Create Individual</button>}
                                {isMobile() && <button className="btn btn-primary pull-right" onClick={() => this.props.history.push({ pathname: '/individual/create/', state: { plan: null } })}>+</button>}
                            </h3>
                            <br />
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label><b>Name</b></label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ name: evt.target.value })}
                                            value={this.state.name} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label><b>Email Address</b></label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ emailID: evt.target.value })}
                                            value={this.state.emailID} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label><b>Phone No</b></label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ phoneNo: evt.target.value })}
                                            value={this.state.phoneNo} />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ marginTop: "20px" }}>
                                    <button className="btn btn-default" onClick={this.onClearSearch}>Clear</button>
                                    <button className="btn btn-primary" onClick={this.onSearchClick}> Search</button>&nbsp;
                                </div>
                            </div>
                            <br />
                            {this.state.loading && <Loader />}
                            {!isMobile() && <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ verticalAlign: "top" }}>Organization</th>
                                        <th scope="col" style={{ verticalAlign: "top" }}>First Name</th>
                                        <th scope="col" style={{ verticalAlign: "top" }}>Last Name</th>
                                        <th scope="col" style={{ verticalAlign: "top" }}>Email Address</th>
                                        <th scope="col" style={{ verticalAlign: "top"}}>Phone No</th>
                                        <th scope="col" style={{ verticalAlign: "top" }}>Status</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.individualList.map((t, index) => <tr key={index}>
                                        <td>{t.organization ? t.organization.name : ''}</td>
                                        <td>{t.name_first}</td>
                                        <td>{t.name_last}</td>
                                        <td>{t.email_address}</td>
                                        <td>{t.phone}</td>
                                        <td>{t.status}</td>
                                        <td>
                                            <div class="btn-group">
                                                <div>
                                                    <Link to={{ pathname: "/individual/edit/", state: { individual: t } }}
                                                        type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>}
                            {isMobile() && <React.Fragment>
                                {this.state.individualList.map((t, index) => <div key={"transaction_" + index} className="transaction">
                                    <div><b>Organization: </b>{t.organization ? t.organization.name : ""}</div>
                                    <div><b>First Name: </b>{t.name_first}</div>
                                    <div><b>Last Name: </b>{t.name_last}</div>
                                    <div><b>Email Address: </b>{t.email_address}</div>
                                    <div><b>Phone No: </b>{t.phone}</div>
                                    <div><b>Status: </b>{t.status}</div>
                                    <div className="transaction-footer">
                                        <Link to={{ pathname: "/individual/edit/", state: { individual: t } }}
                                            type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i> Edit</Link>
                                    </div>
                                </div>)}
                            </React.Fragment>}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}

export default IndividualList
