import React, { Component } from "react";

class Popup extends Component {
  render() {
    const { isPopup, closePopup, handleKYCButton, organization_id } =
      this.props;

    return (
      <div>
        {isPopup && (
          <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">KYC Approval</h5>
                  <button type="button" className="close" onClick={closePopup}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Are You sure you want to approve the organization?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleKYCButton(organization_id, isPopup);
                      closePopup();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closePopup}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Popup;
