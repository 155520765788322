import React, { Component } from 'react'
import Header from './Header'
import '../css/App.css';
import { isMobile } from '../redux/actions';
import Loader from '../components/Loader';
import { authContext } from '../adalConfig'

class IndividualAdd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lastNameError: '',
            organizationIDError: '',
            phoneNoError: '',
            emailIDError: '',

            key: '',
            status: 'active',
            password: "passw0rd",
            firstName: '',
            middleName: '',
            lastName: '',
            organizationID: '',
            phoneNo: '',
            emailID: '',
            organizationList: [],
            loading: true
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    async componentDidMount() {
        let organizationList = []
        organizationList = await this.props.getOrganizations(0, 100, '');
        this.setState({ organizationList: organizationList, loading: false });

        if (this.props.location.state && this.props.location.state.individual) {
            let individual = this.props.location.state.individual;
            this.setState({
                organizationID: individual.organization_id,
                emailID: individual.email_address,
                password: individual.password,
                firstName: individual.name_first,
                middleName: individual.name_middle,
                lastName: individual.name_last,
                phoneNo: individual.phone,
                status: individual.status,
                key: individual.id
            })
        }

    };

    onSave() {
        const { firstName, lastName, middleName, emailID, password, organizationID, status, phoneNo, key } = this.state

        let lastNameError = '', emailIDError = '', phoneNoError = '', organizationIDError = ''

        if (lastName.trim() === '') {
            lastNameError = 'Please specify last name.'
        }

        if (emailID.trim() === '') {
            emailIDError = 'Please specify email address.'
        }

        if (phoneNo.trim() === '') {
            phoneNoError = 'Please specify phone no.'
        }

        if (organizationID.trim() === '') {
            organizationIDError = 'Please select organization.'
        }

        if (lastNameError !== '' || emailIDError !== '' || phoneNoError !== '' || organizationIDError !== '') {
            this.setState({
                lastNameError: lastNameError,
                emailIDError: emailIDError,
                phoneNoError: phoneNoError,
                organizationIDError: organizationIDError
            });
            return;
        }

        let individual = {
            organization_id: organizationID,
            email_address: emailID,
            password: password,
            name_first: firstName,
            name_middle: middleName,
            name_last: lastName,
            phone: phoneNo,
            status: status,
            created_by: authContext._user.profile.name,
            updated_by: authContext._user.profile.name
        }

        if(key !== '') {
            individual.individual_id = key;
        }

        // console.log("individual", individual);

        this.props.saveIndividual(individual, key).then((id) => {
            if (id !== '') {
                alert('Individual saved successfully')
                this.onCancel();
            }
            else {
                alert('Something went wrong. Please contact administrator')
            }

        });
    };


    onCancel() {
        this.setState({
            lastNameError: '',
            organizationIDError: '',
            phoneNoError: '',
            emailIDError: '',

            key: '',
            status: 'active',
            password: "passw0rd",
            firstName: '',
            middleName: '',
            lastName: '',
            organizationID: '',
            phoneNo: '',
            emailID: '',
        });
        this.props.history.push({ pathname: '/individuallist' });
    }

    renderFooter() {
        return <React.Fragment>
            {!isMobile() && <div className="col-md-12">
                <button className="btn btn-default" onClick={() => this.onCancel()}>Cancel</button>
                <button className="btn btn-primary pull-right" onClick={() => this.onSave()} disabled={this.state.saving} >Save</button>
            </div>}
            {isMobile() && <div className="footer">
                <button className="btn btn-default btn-lg" onClick={() => this.onCancel()}>Cancel</button>
                <button className="btn btn-primary  btn-lg" onClick={() => this.onSave()} disabled={this.state.saving} >Save</button>
            </div>}
        </React.Fragment>
    }

    render() {
        return <div>
            <Header menuSelected="transactionadd" {...this.props} />
            <main className="container">
                {/* <br /> */}
                {this.state.loading && <Loader />}
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0"> <span className="fa-stack fa-md">
                                <i className="fa fa-circle-thin fa-stack-2x"></i>
                                <i className="fa fa-plus fa-stack-1x"></i>
                            </span> {this.state.key === '' ? 'Create ' : 'Edit '} Individual
                            </h3>
                            <br />
                            <div className={this.state.organizationIDError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Organization</label>
                                <select className="form-control"
                                    value={this.state.organizationID}
                                    onChange={(evt) => this.setState({ organizationID: evt.target.value, organizationIDError: "" })} >
                                    <option value="">Select Organization</option>
                                    {this.state.organizationList && this.state.organizationList.map((e) => <option value={e.id}>{e.name}</option>)}
                                </select>
                                {this.state.organizationIDError !== "" && <span class="help-block">{this.state.organizationIDError}</span>}
                            </div>
                            <div className="mb-3 col-md-6">
                                <label >First Name</label>
                                <input type="text" className="form-control"
                                    value={this.state.firstName}
                                    onChange={(evt) => this.setState({ firstName: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label >Middle Name</label>
                                <input type="text" className="form-control"
                                    value={this.state.middleName}
                                    onChange={(evt) => this.setState({ middleName: evt.target.value })} />
                            </div>
                            <div className={this.state.lastNameError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Last Name</label>
                                <input type="text" className="form-control"
                                    value={this.state.lastName}
                                    onChange={(evt) => this.setState({ lastName: evt.target.value, lastNameError: "" })}
                                />
                                {this.state.lastNameError !== "" && <span class="help-block">{this.state.lastNameError}</span>}
                            </div>
                            <div className={this.state.emailIDError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Email Address</label>
                                <input type="text" className="form-control"
                                    value={this.state.emailID}
                                    onChange={(evt) => this.setState({ emailID: evt.target.value, emailIDError: '' })}
                                />
                                {this.state.emailIDError !== "" && <span class="help-block">{this.state.emailIDError}</span>}
                            </div>
                            <div className={this.state.phoneNoError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Phone No</label>
                                <input type="text" className="form-control"
                                    value={this.state.phoneNo}
                                    onChange={(evt) => this.setState({ phoneNo: evt.target.value, phoneNoError: '' })}
                                />
                                {this.state.phoneNoError !== "" && <span class="help-block">{this.state.phoneNoError}</span>}
                            </div>
                            {isMobile() && <React.Fragment>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </React.Fragment>}
                            {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}

export default IndividualAdd