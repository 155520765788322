import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from "./redux/store"

import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig'

import * as serviceWorker from './serviceWorker';

import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/index.css';

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
}, DO_NOT_LOGIN);

serviceWorker.register()