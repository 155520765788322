import React from "react";

const ShowingCompareResult = ({ show, onClose, value }) => {
  const modalStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyles = {
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    maxWidth: "800px",
    borderRadius: "5px",
    position: "relative",
    boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
  };

  const closeStyles = {
    color: "#aaa",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const footerStyles = {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  };

  if (!show) {
    return null;
  }

  return (
    <div style={modalStyles} onClick={onClose}>
      <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
        <span style={closeStyles} onClick={onClose}>
          &times;
        </span>
        <h3>Result</h3>
        <textarea
          rows="4"
          cols="50"
          value={value}
          //   onChange={(event) => setTextValue(event.target.value)}
          placeholder="Type your text here..."
          style={{
            minHeight: "440px",
            maxHeight: "500px",
            width: "100%",
          }}
        ></textarea>
        <div style={footerStyles}>
          <button className="btn btn-primary" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowingCompareResult;
