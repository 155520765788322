import thunk from "redux-thunk"
import { createStore, applyMiddleware } from "redux"
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const store = () => {
    return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    // const isDevMode = NODE_ENV !== "production"
    // if (isDevMode) {
    //     return createStore(
    //         allReducers,
    //         composeEnhancers(applyMiddleware(...middleware))
    //     )
    // }
    // return createStore(allReducers, applyMiddleware(...middleware))
}
export default store()