import React, { Component } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { isMobile } from "../redux/actions";
import Loader from "../components/Loader";
import { formatNumber } from "../utils/index";

let subscriptionPlansOrder = ["tracer", "publisher", "verifier"];

class SubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionList: [],
      loading: true,
    };
  }

  async componentDidMount() {
    let subscriptionList = [];
    subscriptionList = await this.props.getSubscriptionCharges();
    subscriptionList = subscriptionList?.plans?.sort((a, b) => {
      let aIndex = subscriptionPlansOrder.indexOf(a.subscription_type);
      let bIndex = subscriptionPlansOrder.indexOf(b.subscription_type);
      if (aIndex !== bIndex) return aIndex - bIndex;
      else return a.sort_order - b.sort_order;
    });
    this.setState({ subscriptionList: subscriptionList, loading: false });
  }

  render() {
    const { subscriptionList } = this.state;
    return (
      <div>
        <Header menuSelected="subscriptionList" {...this.props} />
        <main className="container">
          {this.state.loading && <Loader />}
          <div className="row">
            <div className="col-md-12">
              <div className="my-3 p-3 bg-white rounded shadow-sm">
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-list fa-stack-1x"></i>
                  </span>{" "}
                  Subscriptions List
                  {/* {!isMobile() && <button className="btn btn-primary pull-right" onClick={() => this.props.history.push({ pathname: '/subscription/create/' })}>Add Subscription</button>} */}
                  {/* {isMobile() && <button className="btn btn-primary pull-right" onClick={() => this.props.history.push({ pathname: '/subscription/create/' })}>+</button>} */}
                </h3>

                {this.state.loading && <Loader />}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", width: "70px" }}
                        >
                          Sr. no.
                        </th>
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", width: "15%" }}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          style={{ verticalAlign: "top" }}
                        >
                          No of Products
                        </th>
                        <th className="text-center">No of Batches</th>
                        <th className="text-center">
                          No of Uploaded Documents
                        </th>
                        <th className="text-center">No of User Accounts</th>
                        <th className="text-center">No of Daily Hashes</th>
                        {/* <th className="text-center">Active</th>
                                        <th className="text-center">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptionList && subscriptionList.length ? (
                        subscriptionList.map((item, index) => {
                          return (
                            <SubscriptionListTr
                              item={item}
                              index={index}
                              key={index}
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <div className="txt-no-record">No Record Found</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const SubscriptionListTr = (props) => {
  const {
    item: {
      prouct_name,
      prouct_metadata: {
        num_batches,
        num_products_on_chain,
        num_seats,
        num_uploaded_documents,
        num_daily_hashes,
      },
      id,
      name,
      active,
    },
    index,
  } = props;
  function isUnlimited(value) {
    if (value === -1) {
      return "Unlimited";
    }
    return formatNumber(value);
  }
  return (
    <tr>
      <td className="align-middle">{index + 1}</td>
      <td className="align-middle">{prouct_name}</td>
      <td className="text-center align-middle">
        {isUnlimited(num_products_on_chain) || "-"}
      </td>
      <td className="text-center align-middle">
        {isUnlimited(num_batches) || "-"}
      </td>
      <td className="text-center align-middle">
        {isUnlimited(num_uploaded_documents) || "-"}
      </td>
      <td className="text-center align-middle">
        {isUnlimited(num_seats) || "-"}
      </td>
      <td className="text-center align-middle">
        {isUnlimited(num_daily_hashes) || "-"}
      </td>
      {/* <td><div className={is_active ? "text-center alert-warning btn-subscription" :  "text-center alert-success btn-subscription"}>{is_active ? "Enable" : "Disable"}</div></td>
        <td className="text-center align-middle">
            <div className="btn-group">
                <div>
                    <Link to={{ pathname: "/subscription/edit/" + id, state: { subscriptionID: id } }} type="button" className="btn btn-md btn-flat btn-default " style={{padding: 0, lineHeight: 'initial'}}><i className="fa fa-edit"></i></Link>
                </div>
            </div>
        </td>  */}
    </tr>
  );
};

export default SubscriptionList;
