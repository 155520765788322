import React, { Component } from 'react'
import Header from './Header'
import '../css/App.css';
import { isMobile } from '../redux/actions';
import { authContext } from '../adalConfig'

class OrganizationAdd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name:'',
            description:'',
            tagLine:'',
            key:'',
            file: null,
            logoURL: '',
            nameError:'',
            descriptionError:'',
            tagLineError:'',
            fileError:'',
            ignoreThreshold : ""
        }
        this.onSave = this.onSave.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    
    componentDidMount() {

        if (this.props.location.state && this.props.location.state.organization) {
            let organization = this.props.location.state.organization;
            this.setState({
                name: organization.name,
                description: organization.description,
                logoURL: organization.logo_url,
                tagLine: organization.tag_line,
                key: organization.id,
                ignoreThreshold : organization.ignore_threshold 
            })
        }
    };
    
    onSave() {
        const {name, description, tagLine, logoURL, file, key,ignoreThreshold } = this.state

        let nameError = '', descriptionError = '', tagLineError = '', fileError = ''

        if(name.trim() === '') {
            nameError = 'Please specify name.'
        }

        if(description.trim() === '') {
            descriptionError = 'Please specify description.'
        }

        if(tagLine.trim() === '') {
            tagLineError = 'Please specify tag line.'
        }

        if(logoURL.trim() === '' && file === null) {
            fileError = 'Please select logo.'
        }

        if(nameError !== '' || descriptionError !== '' || tagLineError !== '' || fileError !== '') {
            this.setState({
                nameError: nameError,
                descriptionError: descriptionError,
                tagLineError: tagLineError,
                fileError: fileError
            });
            return;
        }

        const form = new FormData();
        form.append('name', name);
        form.append('fileName', file);
        form.append("short_code", (name.replace(/[@!&\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(' ','_')).toString().toLowerCase());
        form.append("description", description);
        form.append("tag_line", tagLine);
        form.append("logo_url", logoURL);
        form.append("created_by", authContext._user.profile.name);
        form.append("updated_by", authContext._user.profile.name);
        if(key){
            form.append("ignore_threshold",ignoreThreshold)
        }
        
        if(key !== '') {
            form.append("organization_id", key);
        }

        this.props.saveOrganization(form, key).then((id) => {
            if (id !== '') {
                alert('Organization saved successfully')
                this.onCancel();
            }
            else {
                alert('Something went wrong. Please contact administrator')
            }
        });
    };

    onFileChange(event) {
        if(event.target.files && event.target.files.length > 0)
            this.setState({ file: event.target.files[0], fileError: '', logoURL: '' })
    };

    renderFooter(){
        return <React.Fragment>
            {!isMobile() &&  <div className="col-md-12">
                                <button className="btn btn-default" onClick={()=>this.onCancel()}>Cancel</button>
                                <button className="btn btn-primary pull-right" onClick={()=>this.onSave()} disabled={this.state.saving} >Save</button>
                            </div>}
            {isMobile() &&  <div className="footer">
                                <button className="btn btn-default btn-lg" onClick={()=>this.onCancel()}>Cancel</button>
                                <button className="btn btn-primary  btn-lg" onClick={()=>this.onSave()} disabled={this.state.saving} >Save</button>
                            </div>}
        </React.Fragment>
    }

    onCancel() {
        this.setState({
            name: '',
            description: '',
            tagLine: '',
            key: '',
            file: null,
            logoURL: '',
            nameError: '',
            descriptionError: '',
            tagLineError: '',
            fileError: '',
            ignoreThresholdError : ''
        });
        this.props.history.push({ pathname: '/organizationlist' });
    }

    render() {
        return <div>
            <Header menuSelected="transactionadd" {...this.props} />
            <main className="container">
                {/* <br /> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0"> <span className="fa-stack fa-md">
                                <i className="fa fa-circle-thin fa-stack-2x"></i>
                                <i className="fa fa-plus fa-stack-1x"></i>
                            </span> {this.state.key === '' ? 'Create ' : 'Edit '} Organization
                            </h3>
                            <br />
                            <div className={this.state.nameError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Name</label>
                                <div className="input-group-btn">
                                    <input type="text" className="form-control"
                                        value={this.state.name}
                                        onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })} />
                                </div>
                                {this.state.nameError !== "" && <span className="help-block">{this.state.nameError}</span>}
                            </div>
                            <div className={this.state.descriptionError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Description</label>
                                <textarea className="form-control" rows="4"
                                    value={this.state.description}
                                    onChange={(evt) => this.setState({ description: evt.target.value, descriptionError: ''})}
                                />
                                {this.state.descriptionError !== "" && <span className="help-block">{this.state.descriptionError}</span>}
                            </div>
                            <div className={this.state.tagLineError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Tag Line</label>
                                <input type="text" className="form-control"
                                    value={this.state.tagLine}
                                    onChange={(evt) => this.setState({ tagLine: evt.target.value, tagLineError:''})}
                                />
                                {this.state.tagLineError !== "" && <span className="help-block">{this.state.tagLineError}</span>}
                            </div>
                            {this.state.key !== '' &&  
                            <div className="mb-3 col-md-6">
                                <label >How many day you want to give ignore threshold?</label>
                                <input type="number" min={0} className="form-control"
                                    value={this.state.ignoreThreshold}
                                    onChange={(evt) => this.setState({ ignoreThreshold: evt.target.value, ignoreThresholdError:''})}
                                />
                            </div>}
                            <div className={this.state.fileError === "" ? "mb-3 col-md-6" : "mb-3 col-md-6  has-error"}>
                                <label >Logo</label>
                                {this.state.logoURL !== '' && <img src={this.state.logoURL} alt="Logo" height="100px" width="100px" className="form-control" />}
                                <input onChange={this.onFileChange}  type="file" className="form-control" />
                                {this.state.fileError !== "" && <span className="help-block">{this.state.fileError}</span>}
                            </div>
                            {isMobile() && <React.Fragment>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                </React.Fragment>}
                           {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}

export default OrganizationAdd