import React, { Component } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { isMobile } from "../redux/actions";
import Loader from "../components/Loader";

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      offset: 0,
      limit: null,
      title: "",
      loading: true,
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  async componentDidMount() {
    let categoryList = [];
    categoryList = await this.props.getCategorys(
      this.state.offset,
      this.state.limit,
      this.state.title
    );
    this.setState({ categoryList: categoryList, loading: false });
  }

  async onSearchClick() {
    this.setState({ loading: true });
    let categoryList = [];
    categoryList = await this.props.getCategorys(
      this.state.offset,
      this.state.limit,
      this.state.title
    );
    this.setState({ categoryList: categoryList, loading: false });
  }

  async onClearSearch() {
    this.setState({ title: "", loading: true });
    let categoryList = [];
    categoryList = await this.props.getCategorys(
      this.state.offset,
      this.state.limit,
      ""
    );
    this.setState({ categoryList: categoryList, loading: false });
  }

  async onStatusChange(status, id) {
    this.setState({ loading: true });
    const res = await this.props.updateCategoryStatus({
      status,
      category_id: id,
    });
    if (res) {
      this.setState({
        categoryList: this.state.categoryList?.map((item) =>
          item?.id === id ? { ...item, is_active: res?.is_active } : item
        ),
      });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div>
        <Header menuSelected="categoryList" {...this.props} />
        <main className="container">
          {/* <br /> */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  {" "}
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-list fa-stack-1x"></i>
                  </span>{" "}
                  Category List
                  {!isMobile() && (
                    <button
                      className="btn btn-primary pull-right"
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/category/create/",
                        })
                      }
                    >
                      Create Category
                    </button>
                  )}
                  {isMobile() && (
                    <button
                      className="btn btn-primary pull-right"
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/category/create/",
                        })
                      }
                    >
                      +
                    </button>
                  )}
                </h3>
                <br />
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        <b>Title</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ title: evt.target.value })
                        }
                        value={this.state.title}
                      />
                    </div>
                  </div>
                  <div className="col-md-3" style={{ marginTop: "20px" }}>
                    <button
                      className="btn btn-default"
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={this.onSearchClick}
                    >
                      {" "}
                      Search
                    </button>
                    &nbsp;
                  </div>
                </div>
                <br />
                {this.state.loading && <Loader />}
                {!isMobile() && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", width: "50%" }}
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", width: "40%" }}
                        >
                          Image
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.categoryList.map((t, index) => (
                        <tr key={t.id}>
                          <td>{t.title}</td>
                          <td>
                            <img
                              src={t.category_image}
                              width="100px"
                              height="100px"
                            />
                          </td>
                          <td>
                            <div class="btn-group">
                              <div>
                                <Link
                                  to={{
                                    pathname: "/category/edit/",
                                    state: { category: t },
                                  }}
                                  type="button"
                                  class="btn btn-md btn-flat btn-default "
                                >
                                  <i class="fa fa-edit"></i>
                                </Link>
                                <Link
                                  className="form-check form-switch btn btn-md btn-flat btn-default"
                                  onClick={() =>
                                    this.onStatusChange(
                                      t.is_active ? false : true,
                                      t.id
                                    )
                                  }
                                >
                                  <input
                                    className="form-check-input input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked={t.is_active ? "checked" : ""}
                                    onClick={() =>
                                      this.onStatusChange(
                                        t.is_active ? false : true,
                                        t.id
                                      )
                                    }
                                  />
                                </Link>
                                {/* {t.is_active ? (
                                  <Link
                                    onClick={() =>
                                      this.onStatusChange(false, t.id)
                                    }
                                    type="button"
                                    class="btn btn-md btn-flat btn-default"
                                  >
                                    <i class="fa fa-check"></i>
                                  </Link>
                                ) : (
                                  <Link
                                    onClick={() =>
                                      this.onStatusChange(true, t.id)
                                    }
                                    type="button"
                                    class="btn btn-md btn-flat btn-default"
                                  >
                                    <i class="fa fa-times"></i>
                                  </Link>
                                )} */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {isMobile() && (
                  <React.Fragment>
                    {this.state.categoryList.map((t, index) => (
                      <div key={t.id} className="transaction">
                        <div>
                          <b>Title: </b>
                          {t.title}
                        </div>
                        <div>
                          <b>Image: </b>
                          <img
                            src={t.category_image}
                            width="100px"
                            height="100px"
                          />
                        </div>
                        <div className="transaction-footer">
                          <Link
                            to={{
                              pathname: "/category/edit/",
                              state: { transaction: t },
                            }}
                            type="button"
                            class="btn btn-md btn-flat btn-default "
                          >
                            <i class="fa fa-edit"></i> Edit
                          </Link>
                          {t.is_active ? (
                            <Link
                              onClick={() => this.onStatusChange(false, t.id)}
                              type="button"
                              class="btn btn-md btn-flat btn-default"
                            >
                              <i class="fa fa-check"></i>
                            </Link>
                          ) : (
                            <Link
                              onClick={() => this.onStatusChange(true, t.id)}
                              type="button"
                              class="btn btn-md btn-flat btn-default"
                            >
                              <i class="fa fa-times"></i>
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CategoryList;
