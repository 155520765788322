import Main from "./Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  isMobile,
  getOrganizations,
  saveOrganization,
  getIndividuals,
  saveIndividual,
  getSubscriptionCharges,
  saveSubscription,
  getSubscriptionItem,
  getCategorys,
  saveCategory,
  updateCategoryStatus,
  verifyOrganization,
  getDocumentProtection,
  uploadExeFile,
  getLatestExeFile,
  getForbiddenDocumentList,
  uploadDocument,
  compareCoaAndNihProductList,
  getOrgoanizationList,
  verifyCoaDocument,
  findingSubstance,
} from "./redux/actions";

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      isMobile,
      getOrganizations,
      saveOrganization,
      getIndividuals,
      saveIndividual,
      getSubscriptionCharges,
      saveSubscription,
      getSubscriptionItem,
      getCategorys,
      saveCategory,
      updateCategoryStatus,
      verifyOrganization,
      getDocumentProtection,
      uploadExeFile,
      getLatestExeFile,
      getForbiddenDocumentList,
      uploadDocument,
      compareCoaAndNihProductList,
      getOrgoanizationList,
      verifyCoaDocument,
      findingSubstance
    },
    dispatch
  );
}

const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

export default App;
