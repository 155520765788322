import React, { Component } from "react";
import Header from "./Header";
import { getLatestExeFile } from "../redux/actions";
import Loader from "./Loader";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isLoading: false,
    };
  }

  lastUploadedExeFile = async () => {
    const data = await this.props.getLatestExeFile();
    this.setState({ lastExeFile: data?.fileName, fileurl: data?.file_url });
  };

  componentDidMount() {
    this.lastUploadedExeFile();
  }

  handleClick = () => {
    document.getElementById("upload-exe-file").click();
  };

  handleUploadFile = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      this.setState({ file });
      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isLoading: true });
      await this.props.uploadExeFile(formData);
      this.setState({ isLoading: false });
      this.setState({ lastExeFile: null, fileurl: null });
      await this.lastUploadedExeFile();
    } else if (e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      this.setState({ file });
      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isLoading: true });
      await this.props.uploadExeFile(formData);
      this.setState({ lastExeFile: null, fileurl: null });
      this.setState({ isLoading: false });
      await this.lastUploadedExeFile();
    } else {
      console.log("Something went wrong");
    }
  };

  handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleUploadFile(event);
  };

  render() {
    return (
      <div>
        <Header menuSelected="home" {...this.props} />
        <main className="container">
          <input
            id="upload-exe-file"
            onChange={this.handleUploadFile}
            type="file"
            accept=".exe"
            style={{ display: "none" }}
          />
          <div
            onDrop={this.handleDrop}
            onDragEnter={this.handleDragOver}
            onDragOver={this.handleDragOver}
            className="upload-file"
            onClick={this.handleClick}
          >
            {this.state.isLoading
              ? "File uploading..."
              : "📁 Drag and Drop your .exe file here or click to upload"}
          </div>
          <span className="show-file">
            <a href={this.state.fileurl}>Download File</a>{" "}
          </span>
        </main>
      </div>
    );
  }
}

export default Home;
