import React, { Component } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { isMobile } from "../redux/actions";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import Spinner from "../components/Spinner";

class OrganizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationList: [],
      offset: 0,
      limit: null,
      name: "",
      loading: true,
      isPopup: false,
      currentId: "",
      approving: false,
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleKYCButton = this.handleKYCButton.bind(this);
  }

  async componentDidMount() {
    let organizationList = [];
    organizationList = await this.props.getOrganizations(
      this.state.offset,
      this.state.limit,
      this.state.name
    );
    this.setState({
      organizationList: organizationList,
      loading: false,
    });
  }

  async onSearchClick() {
    this.setState({ loading: true });
    let organizationList = [];
    organizationList = await this.props.getOrganizations(
      this.state.offset,
      this.state.limit,
      this.state.name
    );
    this.setState({
      organizationList: organizationList,
      loading: false,
    });
  }

  async onClearSearch() {
    this.setState({ name: "", loading: true });
    let organizationList = [];
    organizationList = await this.props.getOrganizations(
      this.state.offset,
      this.state.limit,
      ""
    );
    this.setState({
      organizationList: organizationList,
      loading: false,
    });
  }

  async handleKYCButton(organization_id, is_approved) {
    this.setState({ approving: true });

    try {
      let verifyOrganization = await this.props.verifyOrganization({
        organization_id,
        is_approved,
      });

      this.setState((prevState) => {
        const updatedOrganizationList = prevState.organizationList.map(
          (org) => {
            if (org.id === organization_id) {
              return {
                ...org,
                is_approved,
              };
            } else {
              return org;
            }
          }
        );

        return {
          organization: verifyOrganization,
          approving: false,
          organizationList: updatedOrganizationList,
        };
      });
    } catch (error) {
      this.setState({
        approving: false,
      });
    }
  }

  closePopup() {
    this.setState({ isPopup: false });
  }

  render() {
    return (
      <div>
        <Header menuSelected="organizationList" {...this.props} />
        <main className="container">
          {/* <br /> */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  {" "}
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-list fa-stack-1x"></i>
                  </span>{" "}
                  Organization List
                  {!isMobile() && (
                    <button
                      className="btn btn-primary pull-right"
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/organization/create/",
                        })
                      }
                    >
                      Create Organization
                    </button>
                  )}
                  {isMobile() && (
                    <button
                      className="btn btn-primary pull-right"
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/organization/create/",
                        })
                      }
                    >
                      +
                    </button>
                  )}
                </h3>
                <br />
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        <b>Name</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ name: evt.target.value })
                        }
                        value={this.state.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-3" style={{ marginTop: "20px" }}>
                    <button
                      className="btn btn-default"
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={this.onSearchClick}
                    >
                      {" "}
                      Search
                    </button>
                    &nbsp;
                  </div>
                </div>
                <br />
                {this.state.loading && <Loader />}
                {!isMobile() && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* <th scope="col" style={{ verticalAlign: "top", width: "10%" }}></th> */}
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", width: "10%" }}
                        >
                          Name
                        </th>
                        {/* <th scope="col" style={{ verticalAlign: "top" , width:"20%"}}>Short Code</th> */}
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Tag Line
                        </th>
                        <th style={{ minWidth: "150px" }}>Ignore Threshold</th>
                        <th>Edit</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.organizationList.map((t, index) => (
                        <tr key={t.id}>
                          <td>{t.name}</td>
                          <td>{t.description}</td>
                          <td>{t.tag_line}</td>
                          <td>
                            {t?.trial_period_end
                              ? new Date(t.trial_period_end).toLocaleDateString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )
                              : "-"}
                          </td>
                          <td>
                            <div class="btn-group">
                              <div>
                                <Link
                                  to={{
                                    pathname: "/organization/edit/",
                                    state: { organization: t },
                                  }}
                                  type="button"
                                  class="btn btn-md btn-flat btn-default "
                                >
                                  <i class="fa fa-edit"></i>
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button
                              className={
                                t?.is_approved ||
                                (this.state.approving &&
                                  this.state.currentId === t?.id)
                                  ? "btn btn-success disabled"
                                  : "btn btn-primary"
                              }
                              onClick={() => {
                                if (!t?.is_approved) {
                                  this.setState({
                                    isPopup: true,
                                    currentId: t?.id,
                                  });
                                }
                              }}
                            >
                              {!this.state.approving ||
                              this.state.currentId !== t.id ? (
                                t?.is_approved ? (
                                  "Approved"
                                ) : (
                                  "Approve"
                                )
                              ) : (
                                <Spinner />
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                      {this.state.isPopup && (
                        <Popup
                          isPopup={this.state.isPopup}
                          organization_id={this.state.currentId}
                          handleKYCButton={this.handleKYCButton}
                          closePopup={this.closePopup}
                        />
                      )}
                    </tbody>
                  </table>
                )}
                {isMobile() && (
                  <React.Fragment>
                    {this.state.organizationList.map((t, index) => (
                      <div key={t.id} className="transaction">
                        <div>
                          <b>Name: </b>
                          {t.name}
                        </div>
                        <div>
                          <b>Description: </b>
                          {t.description}
                        </div>
                        <div>
                          <b>Tag Line: </b>
                          {t.tag_line}
                        </div>
                        <div className="transaction-footer">
                          <Link
                            to={{
                              pathname: "/organization/edit/",
                              state: { transaction: t },
                            }}
                            type="button"
                            class="btn btn-md btn-flat btn-default "
                          >
                            <i class="fa fa-edit"></i> Edit
                          </Link>
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default OrganizationList;
