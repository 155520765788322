import React, { Component } from "react";
import { Route } from "react-router-dom";
import Home from "./components/Home";
import OrganizationAdd from "./components/OrganizationAdd";
import OrganizationList from "./components/OrganizationList";
import CategoryList from "./components/CategoryList";
import CategoryAdd from "./components/CategoryAdd";
import IndividualAdd from "./components/IndividualAdd";
import IndividualList from "./components/IndividualList";
import SubscriptionList from "./components/SubscribeList";
import SubscriberAdd from "./components/SubscriberAdd";
import DocumentProtectionList from "./components/DocumentProtectionList";
import FindingForbiddenSubstance from "./components/FindingForbiddenSubstance";
import CompareCoaAndNihProduct from "./components/CompareCoaAndNihProduct";

class Main extends Component {
  render() {
    return (
      <div>
        <Route
          exact
          path="/"
          render={({ history }) => (
            <div>
              <Home {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/home"
          render={({ history }) => (
            <div>
              <Home {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/organization/create"
          render={({ history }) => (
            <div>
              <OrganizationAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/organization/edit"
          render={({ history }) => (
            <div>
              <OrganizationAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/organizationlist"
          render={({ history }) => (
            <div>
              <OrganizationList {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/categorylist"
          render={({ history }) => (
            <div>
              <CategoryList {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/category/create"
          render={({ history }) => (
            <div>
              <CategoryAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/category/edit"
          render={({ history }) => (
            <div>
              <CategoryAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/individual/create"
          render={({ history }) => (
            <div>
              <IndividualAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/individual/edit"
          render={({ history }) => (
            <div>
              <IndividualAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/individuallist"
          render={({ history }) => (
            <div>
              <IndividualList {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/subscriptionList"
          render={({ history }) => (
            <div>
              <SubscriptionList {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/subscription/create"
          render={({ history }) => (
            <div>
              <SubscriberAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/subscription/edit/:subscriptionID"
          render={({ history }) => (
            <div>
              <SubscriberAdd {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/documentProtectionList"
          render={({ history }) => (
            <div>
              <DocumentProtectionList {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/findforbiddensubstance"
          render={({ history }) => (
            <div>
              <FindingForbiddenSubstance {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/compareproductlabel"
          render={({ history }) => (
            <div>
              <CompareCoaAndNihProduct {...this.props} onHistory={history} />
            </div>
          )}
        />
      </div>
    );
  }
}

export default Main;
