import React, { Component } from "react";
import { isMobile } from "../redux/actions";
import img from "../images/logo100.png";
import { authContext } from "../adalConfig";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      showChangePasswordModal: false,
    };
    this.renderDesktop = this.renderDesktop.bind(this);
  }

  componentDidMount() {
    // if (this.props.common && !this.props.common.user) {
    //     this.props.history.push({ pathname: "/" })
    // }
  }

  renderDesktop() {
    return (
      <div className="menubar" id="menubar">
        <div className="header">
          <div className="container">
            <div>
              <img src={img} alt="" />
            </div>
            <div className="header-title">HealthLoq Admin</div>
          </div>
        </div>
        <div className="menu-items">
          <div className="container">
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/home")}
            >
              Home
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/organizationlist")}
            >
              Organizations
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/documentProtectionList")}
            >
              Document Protection
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/individuallist")}
            >
              Individuals
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/subscriptionList")}
            >
              Subscriptions
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/categorylist")}
            >
              {" "}
              Category{" "}
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/findforbiddensubstance")}
            >
              Result from openAI
            </div>
            <div
              className="menu-item"
              onClick={() => this.props.history.push("/compareproductlabel")}
            >
              Compare Product
            </div>
            <div className="menu-item" onClick={() => authContext.logOut()}>
              Sign Out
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="header-mobile">
          <div
            className="header-mobile-menu-button pull-left"
            onClick={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <svg viewBox="0 0 512 512">
              <g>
                <g>
                  <path d="M501.333,96H10.667C4.779,96,0,100.779,0,106.667s4.779,10.667,10.667,10.667h490.667c5.888,0,10.667-4.779,10.667-10.667    S507.221,96,501.333,96z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M501.333,245.333H10.667C4.779,245.333,0,250.112,0,256s4.779,10.667,10.667,10.667h490.667    c5.888,0,10.667-4.779,10.667-10.667S507.221,245.333,501.333,245.333z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M501.333,394.667H10.667C4.779,394.667,0,399.445,0,405.333C0,411.221,4.779,416,10.667,416h490.667    c5.888,0,10.667-4.779,10.667-10.667C512,399.445,507.221,394.667,501.333,394.667z" />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
          <div
            className="header-mobile-businessname"
            onClick={() => this.props.history.push("/home")}
          >
            HealthLoq Admin
          </div>
        </div>
        {this.state.isOpen && (
          <div
            className="menu-overlay"
            onClick={() => this.setState({ isOpen: false })}
          />
        )}
        <div
          className={
            this.state.isOpen ? "mobile-menu-show" : "mobile-menu-hide"
          }
        >
          <div className="menu-logo">
            <img src={img} alt="" style={{ paddingRight: "20px" }} />
          </div>
          <div className="menu-content">
            <div
              className="menu-row seperator"
              onClick={() => this.props.history.push("/home")}
            >
              <div className="menu-row-text">Home</div>
            </div>
            <div
              className="menu-row seperator"
              onClick={() => this.props.history.push("/organizationlist")}
            >
              Organizations
            </div>
            <div
              className="menu-row seperator"
              onClick={() => this.props.history.push("/individuallist")}
            >
              Individuals
            </div>
            <div
              className="menu-row seperator"
              onClick={() => this.props.history.push("/categorylist")}
            >
              Category
            </div>
            <div
              className="menu-row seperator"
              onClick={() => this.props.history.push("/individuallist")}
            >
              Sign Out
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div>
        {!isMobile() && this.renderDesktop()}
        {isMobile() && this.renderMobile()}
      </div>
    );
  }
}

export default Header;
